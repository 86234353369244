#userbar {
    margin-left: 10px;

    .handle {
        background-size: cover !important;
        border-radius: 50%;
        display: block;
        padding: 0;
        height: 28px;
        width: 28px;
        background-position: center;

        &.not-login {
            
            a {
                background: $blue;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 28px;
                width: 28px;
          
                svg {
                  color: $white;
                  height: 16px !important;
                  width: auto !important;
                }
              }
        }
    }
}

.navbar-session {
    flex-direction: column;

    .navbar-menu {
        margin-right: 0 !important;
        padding: 0;
        
        .navbar-item {
            margin-right: 0 !important;   
        }

        .has-dropdown {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }

    .navbar-menu:first-child {

        .navbar-item {
            margin-bottom: 0;
        }
    }
}
