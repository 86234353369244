
.pagination {
    margin-top: $gap /3;
    margin-bottom: $gap;

    .pagination-link, .pagination-previous, .pagination-next{
        background: #eee;
        border: none;
        border-radius: 0;

        &.is-current {
            background: $grey;
        }
    
        &:hover {
            border-color: $primary !important;
            background-color: $primary;
            color: $white;
        }
    }
}