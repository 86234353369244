$ribbon-color: $grey-darker !default;
$ribbon-background-color: $white !default;
$ribbon-border: 0.1rem solid $grey-lighter !default;

// The ribbon sizes use mixins so they can be used at different breakpoints
@mixin ribbon-small {
  font-size: $size-small;
}

@mixin ribbon-medium {
  font-size: $size-medium;
}

@mixin ribbon-large {
  font-size: $size-large;
}

.has-ribbon {
  position: relative;
}

.has-ribbon-left {
  position: relative;

  .ribbon {
    right: auto;
    left: 0;
    border-left: none;
    border-right: $ribbon-border;
  }
}

.has-ribbon-bottom {
  position: relative;

  .ribbon {
    top: auto;
    bottom: 0.5em;
  }
}

.ribbon {
  background-color: $ribbon-background-color;
  border: $ribbon-border;
  border-right: none;
  color: $ribbon-color;
  font-size: 90% !important;
  justify-content: center;
  padding-left: 0.75em;
  padding-right: 0.75em;
  text-align: center;
  white-space: nowrap;
  position: relative;
  top: 65px;
  right: 0;
  font-weight: 400;
  z-index: 2;
  width: fit-content !important;

  @include desktop {
    font-size: $size-normal !important;
    top: 0.5rem;
    width: 25%;
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name}:not(.is-outlined) {
      background-color: var(--key-color);
      border-color: transparent;
      color: $color-invert !important;
    }

    &.is-#{$name} {
      &.is-outlined {
        background-color: transparent;
        border-color: $color;
      }
    }
  }

  // Sizes
  &.is-small {
    @include ribbon-small;
  }

  &.is-medium {
    @include ribbon-medium;
  }

  &.is-large {
    @include ribbon-large;
  }

  &.is-outlined {
    background-color: transparent;
  }
}
