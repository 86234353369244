@import "~bulma";
@import "~bulma-extensions/bulma-carousel/src/sass/index.sass";
@import "~bulma-extensions/bulma-tooltip/dist/css/bulma-tooltip.sass";
@import "bulma-ribbon.scss";
@import "partials/variables";

@import "partials/fonts";
@import "partials/mixins";
@import "partials/social-menu";
@import "partials/userbar";
@import "partials/user-profile";
@import "partials/navbar";
@import "partials/slider";
@import "partials/breadcrumb";
@import "partials/header";
@import "partials/sidebar-blocks";
@import "partials/social-share";
@import "partials/autoridades";
@import "partials/scrollspy";
@import "partials/pagination";
@import "partials/home-targetbar";
@import "partials/home-advertisements";
@import "partials/listings";
@import "partials/home-links";
@import "partials/home-quote";
@import "partials/block_event";
@import "partials/blocks-quotes";
@import "partials/block-banner";
@import "partials/block-slider";
@import "partials/blocks-columns";
@import "partials/block-suggested";
@import "partials/blocks-description";
@import "partials/blocks-paragraph";
@import "partials/blocks-collapsible";
@import "partials/lists";
@import "partials/map";
@import "partials/footer";
@import "partials/course_plan";
@import "partials/date";
@import "partials/tags";
@import "partials/news";
@import "partials/home-careers";
@import "partials/blocks";
@import "partials/animations";
@import "partials/career";
@import "partials/careers-list";
@import "partials/materia";

/* -------------------------------- 

Estilos generales

-------------------------------- */

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font: {
    size: 100%;
    family: $family-primary;
  }
  color: $black;
  background-color: $blanco;
}

body,
html {
  /* important */

  .has-text-justified {
    text-align: left !important;

    @include tablet {
      text-align: justify !important;
    }
  }
}

a {
  color: $black;
  text-decoration: none;
  transition: 0.5s;

  &:hover {
    color: $verdeAgua;
  }
}

h1,
h2,
h3 {
  font-family: $title-font;
  font-size: 180%;
  font-weight: bolder;
}

.content {
  h3:not(:first-child) {
    margin-top: 0;
  }
}

#container {
  @include touch {
    padding: $gap / 2 $gap / 2 0;
  }
}

.is-bold {
  font-weight: bold;
}

// Anuncios debajo de los encabezados
.anuncios {
  max-width: 80%;
  margin: 20px auto;

  .anuncioSosIngresante {
    text-align: center;

    > * {
      margin: 0 1%;
      display: block;

      @include tablet {
        display: inline-block;
      }
    }

    h3 {
      font-size: 200%;
      font-weight: normal;
    }

    a {
      color: $verdeAgua;
      font-weight: bold;
    }
  }
}

// Listado Oficinas
.listado-landing {
  display: flex;
  flex-wrap: wrap;

  .item {
    background: $pastelClaro;
    color: var(--key-color);
    padding: 2% 1%;
    margin: 1%;
    width: 48%;

    @include tablet {
      width: 23%;
    }

    &:hover {
      background: var(--key-color);
      color: $pastelClaro;
    }

    strong {
      font-size: 110%;
    }
  }
}

// Formulario
form {
  margin: 50px 0;
  width: 100%;

  @include tablet {
    width: 60%;
  }

  .title {
    color: $blue;
    text-transform: uppercase;
  }

  .field {
    input {
      border-radius: 0;
    }

    textarea {
      border-radius: 0;
    }
  }

  .is-grouped {
    .control {
      width: 100%;

      button {
        background: $naranja;
        border-radius: 0;
        font-weight: bold;
        text-transform: uppercase;
        width: 100%;
      }
    }
  }
}

.error-page {
  .content {
    min-height: 100vh;
  }
}

// Listado buscador
.box {
  border-radius: 0;
  box-shadow: none;
  width: 100%;

  .level.is-mobile {
    font-size: $size-7;
    display: inline-block;
    word-wrap: break-word;
  }
}
.error-page {
  .content {
    min-height: 100vh;
  }
}

.profile-headshot {
  margin: 4rem 0 2rem;
}

// Shadows
@function diffuse-shadows(
  $size,
  $color: rgba(0, 0, 0, 0.125),
  $multiply: false
) {
  // via https://codepen.io/marksdk/pen/wvBoNaj
  $val: 0 1px 1px $color;
  @for $i from 1 through $size {
    @if $i % 2==0 {
      @if $multiply==true {
        $i: $i * 2;
      }
      $val:
        #{$val},
        0 #{$i}px #{$i}px #{$color};
    }
  }
  @return $val;
}

@mixin diffuse-text($size, $color, $multiply) {
  text-shadow: diffuse-shadows($size, $color, $multiply);
}

#main-page-title {
  @include diffuse-text(16, rgba($grey-dark, 0.25), false);
}
