.advertisements {
  margin: 32px 0;

  @include desktop {
    margin: 0;
  }

  .glide__arrows {

    .glide__arrow--left {
      left: 0;
    }

    .glide__arrow--right {
      right: 0;
    }

    button {
      color: $grey-dark;
      box-shadow: none;
      border: none;
      font-size: 1rem;
      font-weight: 600;

      &:hover{
        box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.3);
      }
    }
  }
  .glide__slide--active{

    .image{
      display: flex;
      animation-name: adv-pulse;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 3;
      animation-duration: 400ms;

    }
  }
  .media {
    text-align: center;
    text-align: right !important;

    @include tablet {
        text-align: right;
    }

    .media-left {
      margin-right: 2rem;

      .image {
        padding-top: 8px;
        padding-left: 8px;
      }
    }

    .media-content {
      text-align: right !important;
      a {
        &:hover {
          text-decoration: none;
        }
      }
      .title {
        color: $verdeAgua;
        font-weight: $title-weight;
        line-height: $title-line-height;
        text-align: right !important;

      }

      .description {
        line-height: $title-line-height;
        color: $title-color;
        text-align: right !important;

      }
    }
    &:hover {
      .media-content {
        .description {
          color: $verdeAgua;
        }
      }
    }
  }
}

@keyframes adv-pulse {
  0% {
      transform: scale(0.8);
      opacity: 0.4;
  }
  40%{
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
      transform: scale(1);

  }
}