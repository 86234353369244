@font-face {
  font-family: 'Raleway Extralight';
  src: url('../fonts/Raleway-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway-Regular.eot');
  src: url('../fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Raleway-Regular.woff2') format('woff2'),
      url('../fonts/Raleway-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Medium.eot');
    src: local('Raleway Medium'), local('Raleway-Medium'),
        url('../fonts/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-Medium.woff2') format('woff2'),
        url('../fonts/Raleway-Medium.woff') format('woff'),
        url('../fonts/Raleway-Medium.ttf') format('truetype'),
        url('../fonts/Raleway-Medium.svg#Raleway-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway-Bold.eot');
  src: local('Raleway Bold'), local('Raleway-Bold'),
      url('../fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Raleway-Bold.woff2') format('woff2'),
      url('../fonts/Raleway-Bold.woff') format('woff'),
      url('../fonts/Raleway-Bold.ttf') format('truetype'),
      url('../fonts/Raleway-Bold.svg#Raleway-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
  
@font-face {
  font-family: 'Dosis';
  src: url('../fonts/Dosis-Regular.eot');
  src: url('../fonts/Dosis-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Dosis-Regular.woff2') format('woff2'),
  url('../fonts/Dosis-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Dosis';
    src: url('../fonts/Dosis-Medium.eot');
    src: local('Dosis Medium'), local('Dosis-Medium'),
        url('../fonts/Dosis-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Dosis-Medium.woff2') format('woff2'),
        url('../fonts/Dosis-Medium.woff') format('woff'),
        url('../fonts/Dosis-Medium.ttf') format('truetype'),
        url('../fonts/Dosis-Medium.svg#Dosis-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis';
    src: url('../fonts/Dosis-Bold.eot');
    src: local('Dosis Bold'), local('Dosis-Bold'),
        url('../fonts/Dosis-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Dosis-Bold.woff2') format('woff2'),
        url('../fonts/Dosis-Bold.woff') format('woff'),
        url('../fonts/Dosis-Bold.ttf') format('truetype'),
        url('../fonts/Dosis-Bold.svg#Dosis-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Droid Serif';
    src: url('../fonts/DroidSerif.eot');
    src: local('Droid Serif'), local('DroidSerif'),
        url('../fonts/DroidSerif.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DroidSerif.woff2') format('woff2'),
        url('../fonts/DroidSerif.woff') format('woff'),
        url('../fonts/DroidSerif.ttf') format('truetype'),
        url('../fonts/DroidSerif.svg#DroidSerif-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Droid Serif';
    src: url('../fonts/DroidSerif-Italic.eot');
    src: local('Droid Serif Italic'), local('DroidSerif-Italic'),
        url('../fonts/DroidSerif-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DroidSerif-Italic.woff2') format('woff2'),
        url('../fonts/DroidSerif-Italic.woff') format('woff'),
        url('../fonts/DroidSerif-Italic.ttf') format('truetype'),
        url('../fonts/DroidSerif-Italic.svg#DroidSerif-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}
