// Autoridades sideber
.oficina,
.materia {

  #container {

    > .columns {

      @include desktop {
        display: flex;
        flex-flow: wrap;
      }
    }
  }
}

.authorities {
  margin-bottom: $gap / 2;

  li {
    margin-top: 7px;
    
    .autoridad-card {
      border: 1px solid $grey-light;
      box-sizing: border-box;
      border-radius: 4px;
      color: $blanco;
      padding: $gap / 5;
      position: relative;
      z-index: 1;

      &:first-child {
        background: var(--key-color);
        border: 1px solid var(--key-color);
      }

      &:hover {
        color: $grey-dark;
      }

      .no-image {
        display: none;
      }
      .image{
        background-position: center;
        background-size: cover;
        border-radius: 50%;
      
      }
    }
  }
}

