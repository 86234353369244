.event .block-heading,
.block-Encabezado,
.block-accordion {

    &:first-child {
        .collapsible {
            padding: 0 0 $gap / 3;
        }
    }

    .collapsible {
        padding: $gap / 3 0;
    }

    &:last-child {
        border-bottom: none !important;
        margin-bottom: $gap / 3;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none !important;
        }

        svg {
            vertical-align: baseline;
        }

        h2 {
            font-size: $size-5 !important;
            font-weight: $weight-medium !important;
            display: inline;
            margin: 0;
            vertical-align: baseline;
        }
    }
}