.course_plan {
  margin-top: -$gap / 6;
  margin-bottom: $gap;

  .block-Encabezado{
    padding: 0;
  }

  .course_grid {
    padding: $gap / 2 0;

    @include tablet {
      padding:$gap / 2;
    }

    .year {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      border-bottom: thin solid $grey-light;
      justify-content: space-between;
      padding-bottom: $gap /4;
      margin-bottom: $gap /4;

      @include tablet {
        flex-flow: inherit;
      }

      .label {
        padding: 0 10px;
        width: 90%;

        @include tablet {
          padding: 0;
          width: 12%;
        }
      }

      .quarters {
        display: block;
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;

        @include tablet {
          display: flex;
          width: 90%;
        }

        .quarter {
          flex: auto;
          margin: 0;
          padding: 0;

          @include tablet {
            flex: 2;
          }

          &:before {
            color: $grey;
            display: block;
            font-size: $size-7;
            padding: 0 10px 10px;
          }

          &.febrero {

            @include tablet {
              width: 50% !important;
            }

            &:before {
              content: "Cursillo"; 
            }

            .subject{

              .actions{
                right: 0;
                padding-right: 0;

                @include tablet {
                  right: 50%;
                  padding-right: 64px;
                }
              }
            }
          }

          &.primer-cuatrimestre {

            &:before {
              content: "Primer cuatrimestre"; 
            }
          }
          
          &.segundo-cuatrimestr {

            &:before {
              content: "Segundo Cuatrimestre";
            }
          }

          &.anual {
            width: 100%;
            flex: 1 100%;
            margin-bottom: 16px;

            &:before {
              content: "Anuales";
            }

            .subject {
              border-bottom: 1px solid $grey-light;
              width: 100%;

              @include tablet {
                width: 50%;
              }

              .actions{
                right: 0;
                padding-right: 0;

                @include tablet {
                  padding-right: 64px;
                }
              }
            }
          }

          .subject {
            padding: 0 10px 10px;
            position: relative;

            &:last-child {
              padding-bottom: 0;
            }

            &:hover {

              > a {
                color: var(--key-color);
              }

              .actions {
                opacity: 1;
              }
            }

            > a {
              color: $grey-dark;
              font-weight: 500;
              font-size: .9em;
              text-transform: uppercase;

              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 70%;
            }

            .data {
                display: none;
            }
          
            .actions {
              position: absolute;
              right: 0;
              display: inline-block;
              vertical-align: middle;


              @include tablet {
                right: $gap;
              }

              a {
                color: var(--key-color);
                padding: 0 5px;
                
                &:hover {
                  color:$grey;
                }
                                
                &:not:last-child {
                  padding-right: 0;
                }
              }
              
            }
          }
        }
      }

      &.optativa,
      &.especialidad {

        .quarters {
          display:block;

          .quarter {
            float:left;
            width: 100%;

            @include tablet {
              width: 50%;
            }

            .subject {
              padding: 0 10px 10px;
            }
          }
        }
      }

      &.optativa h2::before {
        content: "Optativas";
      }
      
      &.especialidad h2::before {
        content: "Especialidades";
      }
    }
  }
}