// Slider carreras
.home .glide,
.carousel {
  margin-top: 66px;
  overflow: hidden;

  @include desktop {
    margin-top: 0;
  }

  .glide__slides {
    display: flex;
    margin-bottom: 0;
    height: 400px;
    z-index: 20;

    .glide__slide {
      position: relative;
      width: 100%;
      overflow: hidden;
      left: 0;
    }

    .particle_field {
      position: absolute;
      right: 0;
      height: 100%;
      width: 50%;
      top: 0;
      z-index: 0;
    }
  }

  

  .carousel-thumbs {
    display: none;
    margin-top: unset;
    position: relative;
    z-index: 22;

    @include widescreen {
      display: block;
    }

    ul {
      display: flex;
      bottom: 0;
      left: 0;
      margin-bottom: 0;
      width: 100%;
      transform: unset;

      li {
        flex-grow: 1;
        padding: 0.27em;
        margin: 0;
        height: 52px;
        border-radius: unset;
        box-shadow: none;
        border: none;

        &:hover {
          border: none;
        }

        &:last-child {
          margin-right: 0;
        }

        &:nth-child(1) {
          background: rgba(0, 0, 0, 0.35);
        }

        &:nth-child(2) {
          background: rgba(0, 0, 0, 0.3);
        }

        &:nth-child(3) {
          background: rgba(0, 0, 0, 0.25);
        }

        &:nth-child(4) {
          background: rgba(0, 0, 0, 0.2);
        }

        &:nth-child(5) {
          background: rgba(0, 0, 0, 0.15);
        }

        &:nth-child(6) {
          background: rgba(0, 0, 0, 0.1);
        }

        &:nth-child(7) {
          background: rgba(0, 0, 0, 0.05);
        }

        &:nth-child(8) {
          background: rgba(0, 0, 0, 0.1);
        }

        a {
          color: $blanco;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 12px;
          text-decoration: none;
          vertical-align: middle;
          justify-content: center;
          letter-spacing: normal;
          height: 100%;

          span {
            display: block;
          }
        }
      }
    }
  }
}

.carousel-navigation {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 35%;
  height: 0;

  .carousel-nav-left,
  .carousel-nav-right {
    background: none;
    border: 0;
    outline: none;
    color: $blanco;
    font-size: 2em;
    height: 2.4em;
    width: 1.4em;
    z-index: 20;
    cursor: pointer;
  }
}

.slider {
  color: $blanco;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;

  h3 {
    color: $blanco !important;
    padding-bottom: 2%;
    font-weight: 700;
    text-transform: uppercase;
  }

  .slide-bajada {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: normal;
    width: 100%;

    @include tablet {
      width: 80%;
    }
  }

  a {
    color: $blanco;
    font-weight: bold;
    font-size: 14px;
    display: block;
    margin-top: 20px;
    font-style: italic;

    &:hover {
      color: $grey-lighter;
    }
  }

  .columns {
    display: flex;
    align-items: center;
    margin-top: 0;
    height: 100%;

    .column {
      padding: 30px 60px;
      &:last-child {
        height: 80%;
      }
    }
  }
}

.sliderAnimate {
  background: url(../img/sliderDots.png) top center;
}

.particle_field {
  img {
    display: none;
    position: absolute;
    &.active {
      display: block;
      transform: scale(0.6);
      animation: zoomInOut 2s linear 0s infinite normal;
    }
  }
}

.glide__slide {
  &:nth-child(7) {
    .particle-1 {
      width: 25%;
      left: -15%;
      top: 25%;
      transform: rotate(25deg);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-2 {
      width: 42%;
      left: 5%;
      bottom: -50px;
      transform: rotate(20deg);
      animation: zoomInOut 5s linear 0s infinite normal;
    }

    .particle-3 {
      bottom: -3%;
      right: -5%;
      width: 32%;
      transform: rotate(85deg);

      @include mobile {
        display: none;
      }
    }

    .particle-4 {
      top: -30%;
      right: -15%;
      width: 48%;
      transform: rotate(40deg);
      animation: zoomInOut 10s linear 0s infinite normal;

      @include mobile {
        display: none;
      }
    }

    .particle-5 {
      width: 40%;
      left: 10%;
      top: -25%;
      transform: rotate(25deg);
      animation: zoomInOut 3s linear 0s infinite normal;
    }

    .particle-6 {
      left: 43%;
      top: 25%;
      width: 32%;
      transform: rotate(0deg) scaleX(-1);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-7 {
      left: -5000px;
      display: none;
    }

    .particle-8 {
      display: none;
    }
  }

  &:nth-child(1) {
    .particle-1 {
      width: 25%;
      left: -15%;
      top: 25%;
      transform: rotate(25deg);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-2 {
      width: 42%;
      left: 5%;
      bottom: -50px;
      transform: rotate(20deg);
      animation: zoomInOut 5s linear 0s infinite normal;
    }

    .particle-3 {
      bottom: -3%;
      right: -5%;
      width: 32%;
      transform: rotate(85deg);

      @include mobile {
        display: none;
      }
    }

    .particle-4 {
      top: -30%;
      right: -15%;
      width: 48%;
      transform: rotate(40deg);
      animation: zoomInOut 10s linear 0s infinite normal;

      @include mobile {
        display: none;
      }
    }

    .particle-5 {
      width: 40%;
      left: 10%;
      top: -25%;
      transform: rotate(25deg);
      animation: zoomInOut 3s linear 0s infinite normal;
    }

    .particle-6 {
      left: 43%;
      top: 25%;
      width: 32%;
      transform: rotate(0deg) scaleX(-1);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-7 {
      left: -5000px;
      display: none;
    }

    .particle-8 {
      display: none;
    }
  }

  &:nth-child(2) {
    .particle-1 {
      width: 28%;
      left: -15%;
      top: 23%;
      transform: rotate(-9deg);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-2 {
      width: 40%;
      left: 10%;
      top: -34%;
      transform: rotate(-64deg);
      animation: zoomInOut 3s linear 0s infinite normal;
    }

    .particle-3 {
      top: -30%;
      right: -15%;
      width: 50%;
      transform: rotate(40deg);
      animation: zoomInOut 10s linear 0s infinite normal;

      @include mobile {
        display: none;
      }
    }

    .particle-4 {
      bottom: -3%;
      right: -5%;
      width: 32%;
      transform: rotate(32deg);

      @include mobile {
        display: none;
      }
    }

    .particle-5 {
      width: 40%;
      left: 5%;
      bottom: -50px;
      transform: rotate(44deg);
      animation: zoomInOut 5s linear 0s infinite normal;
    }

    .particle-6 {
      left: 38%;
      top: 29%;
      width: 32%;
      transform: rotate(13deg) scaleX(-1);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-7 {
      left: -5000px;
      display: none;
    }

    .particle-8 {
      display: none;
    }
  }

  &:nth-child(3) {
    .particle-1 {
      width: 20%;
      left: -20%;
      bottom: 20%;
      transform: rotate(175deg);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-2 {
      width: 31%;
      left: -3%;
      bottom: 34%;
      transform: rotate(126deg);
      animation: zoomInOut 3s linear 0s infinite normal;
    }

    .particle-3 {
      width: 48%;
      left: 0;
      top: -48%;
      transform: rotate(1deg);
      animation: zoomInOut 5s linear 0s infinite normal;
    }

    .particle-4 {
      right: -15%;
      width: 30%;
      top: -20%;
      transform: rotate(-108deg);
    }

    .particle-5 {
      right: -4%;
      bottom: 4%;
      width: 40%;
      transform: rotate(123deg);

      @include mobile {
        display: none;
      }
    }

    .particle-6 {
      bottom: 0;
      left: 24%;
      width: 36%;
      transform: rotate(3deg);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-7 {
      bottom: 53%;
      left: 42%;
      width: 30%;
      transform: rotate(-58deg);
      animation: zoomInOut 10s linear 0s infinite normal;

      @include mobile {
        display: none;
      }
    }

    .particle-8 {
      width: 130px;
      top: 10px;
      display: none;
    }
  }

  &:nth-child(4) {
    .particle-1 {
      width: 19%;
      left: -25%;
      bottom: 20%;
      transform: rotate(170deg);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-2 {
      bottom: 34%;
      left: -8%;
      width: 26%;
      transform: rotate(104deg);
      animation: zoomInOut 3s linear 0s infinite normal;
    }

    .particle-3 {
      width: 38%;
      left: 0;
      top: -30%;
      transform: rotate(-5deg);
      animation: zoomInOut 5s linear 0s infinite normal;
    }

    .particle-4 {
      width: 28%;
      top: -8%;
      right: -8%;
      transform: rotate(136deg);
    }

    .particle-5 {
      right: 4%;
      bottom: 2%;
      width: 33%;
      transform: rotate(195deg);

      @include mobile {
        display: none;
      }
    }

    .particle-6 {
      bottom: 0;
      left: 14%;
      width: 34%;
      transform: rotate(-10deg);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-7 {
      bottom: 40%;
      left: 36%;
      width: 40%;
      transform: rotate(95deg);
      animation: zoomInOut 10s linear 0s infinite normal;

      @include mobile {
        display: none;
      }
    }

    .particle-8 {
      width: 130px;
      top: 10px;
      display: none;
    }
  }

  &:nth-child(5) {
    .particle-1 {
      width: 14%;
      left: -14%;
      top: 15%;
      transform: rotate(160deg);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-2 {
      width: 30%;
      left: -10%;
      bottom: 20%;
      transform: rotate(-34deg);
      animation: zoomInOut 5s linear 0s infinite normal;
    }

    .particle-3 {
      bottom: 6%;
      left: 21%;
      width: 26%;
      transform: rotate(52deg);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-4 {
      right: 2%;
      top: 36%;
      width: 45%;
      transform: rotate(-6deg);
    }

    .particle-5 {
      top: 1%;
      right: 20%;
      width: 20%;

      @include mobile {
        display: none;
      }
    }

    .particle-6 {
      bottom: 50%;
      left: 25%;
      width: 30%;
      transform: rotate(260deg);
      animation: zoomInOut 10s linear 0s infinite normal;

      @include mobile {
        display: none;
      }
    }

    .particle-7 {
      width: 25%;
      left: 0%;
      top: -8%;
      transform: rotate(100deg);
      animation: zoomInOut 3s linear 0s infinite normal;
    }

    .particle-8 {
      width: 130px;
      top: 10px;
      display: none;
    }
  }

  &:nth-child(6) {
    .particle-1 {
      width: 14%;
      left: -14%;
      top: 15%;
      transform: rotate(160deg);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-2 {
      width: 30%;
      left: -10%;
      bottom: 20%;
      transform: rotate(-34deg);
      animation: zoomInOut 5s linear 0s infinite normal;
    }

    .particle-3 {
      bottom: 6%;
      left: 21%;
      width: 26%;
      transform: rotate(52deg);
      animation: zoomInOut 8s linear 0s infinite normal;
    }

    .particle-4 {
      right: 2%;
      top: 36%;
      width: 45%;
      transform: rotate(-6deg);
    }

    .particle-5 {
      top: 1%;
      right: 20%;
      width: 20%;

      @include mobile {
        display: none;
      }
    }

    .particle-6 {
      bottom: 50%;
      left: 25%;
      width: 30%;
      transform: rotate(260deg);
      animation: zoomInOut 10s linear 0s infinite normal;

      @include mobile {
        display: none;
      }
    }

    .particle-7 {
      width: 25%;
      left: 0%;
      top: -8%;
      transform: rotate(100deg);
      animation: zoomInOut 3s linear 0s infinite normal;
    }

    .particle-8 {
      width: 130px;
      top: 10px;
      display: none;
    }
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slideLeft {
  from {
    opacity: 0;
    // transform: translate(-30px);
  }

  to {
    opacity: 1;
    // transform: translate(0px);
  }
}

@keyframes slideRight {
  from {
    left: 0;
    opacity: 1;
  }

  to {
    left: -30px;
    opacity: 0;
  }
}
