.block-quote {
    margin-bottom: $gap / 2;
    font-family: $family-tertiary;
    display: flex;
    justify-content: center;

    & > div {
        position: relative;
        
        @include tablet {
            width: 70%;
        }

        &:before {
            content: " ";
            border-right: 3px solid $home-news-1;
            display: none;
            position: absolute;
            display: none;
            height: 50%;
            right: $gap / 2;
            top: 25%;

            @include tablet {
                display: block;
            }
        }

        &:after {
            content: "\201d";
            color: #ececec;
            display: none;
            font-size: 24rem;
            left: - $gap / 2;
            bottom: $gap * 2;
            line-height: 0;
            transform: rotate(180deg);
            position: absolute;
            z-index: 0;

            @include tablet {
                display: block;
            }
        }

        blockquote {
            background-color: unset;
            padding: $gap / 2;
            color: $home-news-1;
            font-size: 1.3rem;
            font-style: italic;
            border: none;
            text-align: left;
            position: relative;
            z-index: 2;

            @include tablet {
                padding: $gap / 4 $gap / 2;
            }
        }
    
        .author {
            font-family: $family-tertiary;
            text-align: right;
            position: relative;
            margin-top: -$gap / 2;
            padding: 0 $gap / 2;
            z-index: 2;

            @include tablet {
                padding: 0 $gap;
            }
        }
    }

    &.left {
        @include tablet {
            float: left;
            width: 50%;
        }

        & > div {
            width: 100%;

            blockquote {
                text-align: right;

                @include tablet {
                    padding-right: 4rem;
                }
            }

            .author {
                
                @include tablet {
                    text-align: right;
                }
            }
        }
    }

    &.right {
        @include tablet {
            float: right;
            width: 50%;
        }

        & > div {
            width: 100%;

            &:before {
                border-left: 3px solid $home-news-1;
                border-right: 0;
                left: $gap / 2;
            }

            &:after {
                bottom: 0;
                left: inherit;
                right: - $gap;
                transform: rotate(360deg);
            }

            blockquote {
                text-align: left;

                @include tablet {
                    padding-left: 4rem;
                }
            }

            .author {
                text-align: left;
            }
        }
    }

    &.full {
        float: none;
        width: 100%;

        & > div {

            &:before {
                border-left: 0;
                border-right: 0;
            }

            .author {
                text-align: left;
                
                @include tablet {
                    text-align: center;
                }
            }
        }
    }
}
