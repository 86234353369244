// Materia
.materia {
  .header {
    padding: 90px 0 30px;

    @include desktop {
      margin: 20px 0;
    }

    div {
      order: unset;
    }

    h1 {
      margin: 0;
    }

    .news-date {
      margin-top: 2rem;
    }
  }

  .extra-info {
    margin-bottom: 2rem;
    display: grid;
    gap: 1rem;

    .info-item {
      .info-label {
        color: $grey;
        font-weight: bolder;
        margin-bottom: 0.8rem;
      }

      .info-data {
        color: var(--key-color);
        font-weight: bolder;
      }

      .item {
        display: flex;
        margin-bottom: 0.6rem;
        width: fit-content;

        figure {
          margin: 0 0.5rem;
        }
      }
    }
  }

  #minimum-content {
    margin: 5rem 0;

    #content-title {
      margin-bottom: 1.5rem;
    }

    #fundamentacion {
      font-size: 1.5rem !important;
      font-weight: 600 !important;
    }

    .content {
      margin-bottom: unset;

      .block-Encabezado {
        margin-top: 5rem;
      }
    }
  }

  .is-one-quarter {
    .block-User,
    .block-Professor,
    .block-Events,
    .block-News {
      ul {
        margin: 8px;

        li {
          padding: 8px;
        }
      }
    }

    .users {
      .j1 {
        .is-64x64 {
          background-color: $grey-light;
          font-size: 3rem;
          text-align: center;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 64px;
            height: 64px;
          }
        }
      }
      .j2 {
        .is-64x64 {
          background-color: $grey-light;
          font-size: 3rem;
          text-align: center;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 48px;
            height: 48px;
          }
        }
      }
      .j3,
      .j4 {
        figure {
          display: none;
        }
      }
    }
    .events {
      padding: $gap/8;
      li {
        display: flex;
        .is-small {
          font-size: $size-5;
          font-weight: bolder;
          padding: 2px 8px;
          color: $blanco;
          border: 1px solid $grey;
          background-color: $grey-dark;
        }
        h4 {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .block-Encabezado a {
    color: var(--key-color);
  }
}
