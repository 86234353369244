.Perfiles {

    .profile-headshot {
        align-items: start;

        h1 {
            margin-top: 0;
            margin-bottom: 0;
        }

        .user-mail {
            margin: $gap / 4 0 $gap / 2;
        }

        .image {

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .section {
            padding: 0;

            .container {
                padding: 0;
            }
        }
    }

    .content {

        table {
    
            thead {
                background: $blue;
                
                tr {
    
                    th {
                        color: $white;
                    }
                }
            }
        }
    }

    .listado {

        .card {

            .card-content {
                color: $card-color;
                margin-bottom: inherit;
            }
        }
    }
}