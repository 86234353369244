.navbar {
  .navbar-brand {
    justify-content: space-between;

    .logo {
      padding: 0;
      padding-right: 0.25rem;
      &:hover {
        background: $blanco;
      }

      img {
        max-height: initial;
        max-width: initial;
        height: $logoSmall;
        width: $logoSmall;

        @include desktop {
          height: $logoMedium;
          width: $logoMedium;
        }
      }
    }
    .navbar-burger {
      align-self: center;
      border: 1px solid $grey-light;
      border-radius: 50%;
      height: 2.25rem;
      width: 2.25rem;
      margin-left: 0;

      span {
        left: calc(50% - 10px);
        width: 20px;
        height: 2px;
      }
    }
  }
}
@mixin menu-classes {
  @for $i from 0 through 10 {
    .menu-#{$i}-color {
      $backColor: lighten($menu-0, $i * 7%) !important;
      background-color: $backColor;
      &:focus {
        background-color: $backColor;
      }
      a:focus {
        background-color: transparent !important;
      }
    }
  }
}

.navbar-container {
  @include desktop {
    display: flex;
    flex-direction: column;
  }

  .navbar-menu {
    & > .navbar-item > a {
      display: block;
    }
    & > .navbar-item {
      background: initial;
      background-color: $blue;
    }
    & > .navbar-item,
    & > .navbar-item > a {
      color: $blanco;
      font-weight: 500;
      font-family: $family-secondary;
      font-size: $size-7;
      margin-left: 0;
      text-transform: uppercase;
      transition: 0.5s;
      & > a:hover,
      &:hover,
      &.is-active {
        background-color: $grey-lighter;
        color: $grey-lighterHover;
      }
      &.is-active .navbar-link:after {
        border: 2px solid $grey-lighterHover;
        border-bottom: 0;
        border-left: 0;
        top: 60%;
      }
      .navbar-link {
        color: $blanco;
        &:after {
          border: 2px solid $blanco;
          border-top: 0;
          border-right: 0;
        }

        &:hover {
          background-color: $grey-lighter !important;
          color: $grey-lighterHover !important;

          &:after {
            border: 2px solid $grey-lighterHover;
            border-bottom: 0;
            border-left: 0;
            top: 60%;
          }
        }
      }

      .dropdown-hidden {
        visibility: hidden;
        opacity: 0;
        display: none;
      }
    }

    // Search
    #search-control {
      padding: 0 !important;
      margin: 0 0 8px;
      position: relative;
      width: 100%;
      background-color: $grey-lighter;
      order: -1;

      @include desktop {
        background-color: $blue;
        order: 0;
        margin: 0 0 0 4px;
        width: 40px;
      }

      &.is-form-active {
        background-color: transparent;

        button {
          color: $grey-lighterHover;
        }
      }

      form {
        width: 100%;
        height: 100%;
        transition: 0.3s all;

        @include desktop {
          position: absolute;
        }

        &.is-form-active {
          background-color: $grey-lighter;
          width: 200px;
          transition: 0.5s all;
        }
      }

      .field {
        margin: 0;
        padding: 2px;
      }

      .input,
      .button {
        border: 0;
        background: transparent;
        border-color: #dbdbdb;
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
        max-width: 100%;
        width: 100%;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 4px;
        box-shadow: none;
        display: inline-flex;
        font-size: 1rem;
        height: 1.9em;
        justify-content: flex-start;
        line-height: 1.5;
        padding-bottom: calc(0.375em - 1px);
        padding-left: calc(0.625em - 1px);
        padding-right: calc(0.625em - 1px);
        padding-top: calc(0.375em - 1px);
        position: relative;
        vertical-align: top;
      }

      .button {
        color: $grey;

        @include desktop {
          color: $blanco;
        }
      }

      .input {
        @include desktop {
          display: none;
        }
      }

      &:focus {
        .button {
          color: $grey;
        }

        input {
          display: inline-flex;
        }
      }
    }
  }
}
#main-navigation {
  align-self: flex-end;
  .navbar-item {
    margin: 0.25rem 0.25rem 0 0;
  }
  @include menu-classes;
}

#audiences-navigation {
  flex-wrap: wrap;

  @include tablet {
    flex-direction: row;
    .navbar-item {
      flex-grow: 0;
      flex-shrink: 1;
    }
  }
  &.is-active {
    display: flex;
  }
}

.navbar-item.is-mega {
  position: static;

  .navbar-dropdown {
    .level_1 .button {
      color: #288bab;
      border-color: #288bab;
    }
    .level_1 {
      ul li a{
        padding: 2px;
        text-transform: initial;
        &:hover {
          color: $blue !important;
          font-weight: bolder;
        }
      }
    }
    @include mobile {
      .media {
        flex-direction: column;
      }
    }

    .media-left p img {
      max-height: unset;
      min-width: 150px;
    }

    .media-content {
      text-transform: none;
    }

    .links .level_1 > .title {
      position: relative;
    }

    .links .level_1 > .title::after {
      content: "";
      border-bottom: 1px solid $grey-dark;
      height: 1px;
      bottom: 2px;
      width: 100%;
      color: #ccc;
      left: 0;
      position: absolute;
    }

    &.is-active {
      opacity: 1;
      visibility: visible;
      display: flex;

      .media-content {
        color: $grey-lighterHover;
      }
    }
  }
}
