.block-paragraph {

    p {
        margin-bottom: $text-margin;
    }

    ul,
    ol {
        margin-left: $list-margin;
    }
}