.description {
    font-weight: 500;
    line-height: 28px;
    font-size: 14px;
    text-align: justify;
    letter-spacing: 0.05em;

    a {
        text-decoration: underline;
    }
}