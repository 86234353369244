// Cita
.home-quote {
    background: $verdeAgua;
    position: relative;
    color: $blanco;
    margin-top: $gap;
    
    @include mobile {
      overflow: hidden;
    }
  
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: url(../img/quoteDots.png);
    }

    blockquote {
      height: 300px;
      align-items: center;
      justify-content: center;
      display: flex;

      @include desktop {
        height: auto;
      }
  
      &.columns {
  
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .quote-image {
      display: none;
      position: relative;
  
      @include tablet {
        display: block;
      }
  
      &:before {
        background: url(../img/quoteImgBefore.png) no-repeat top left;
        background-size: contain;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: -70px;
        left: -180px;
        z-index: 2;
      }

      &:after {
        background: url(../img/quoteImgAfter.png) no-repeat top right;
        background-size: contain;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 60px;
        left: -18px;
        z-index: 0;
      }
  
      img {
        position: relative;
        display: block;
        z-index: 1;
  
        @include tablet {
          margin-top: -100px;
        }
  
        @include desktop {
          margin-top: -60px;
        }
      }
    }
  
    .quote-description {
      font-family: $family-tertiary;
      align-self: center;
      position: relative;

      @include desktop {
        margin: $gap / 2 $gap * 2 $gap / 2 $gap / 2;
      }
  
      &:before {
        background: url(../img/quote.png) no-repeat top left;
        background-size: contain;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: -145px;
        left: -10px;
      }

      &:after {
        background: url(../img/quote.png) no-repeat top left;
        background-size: contain;
        transform: rotate(180deg);
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 145px;
        left: 10px;
      }
  
      p {
        font-size: $size-5;
        font-style: italic;
        line-height: 1.6em;
        text-align: center;
      }
  
      h3 {
        font-size: $size-5;
        font-weight: bold;
        text-align: center;
        margin-top: 20px;
        color: $blanco;
        @include desktop {
          text-align: right;
        }
      }
    }
  }