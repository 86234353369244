.home-links {
  padding: 2.5rem 1.5rem;
    .container {

        > .columns {

            @include tablet-only {
                align-items: center;
            }

            .column {
              position: relative;
              @include tablet-only {
                  width: 50%;
              }
            }
        }
    }
}

.linkbar {
  .flat-menu.links {
    ul {
      li {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        border-radius: 3px;
        &:hover {
          background-color:$grey-dark;
          box-shadow: 0 2px 5px rgba(50, 50, 50, 0.4);
          a {
            color: $blanco !important;
            text-decoration: none;
            img{
              filter: brightness(100);
            }
          }
        }

        a {
          color: $grey-dark;
          padding-bottom: $gap / 8;
          display: block;
          height: 100%;
          width: 100%;
        }
      }
    }
    .item-text {
      word-wrap: normal;
      font-weight: $title-weight;
      line-height: $title-line-height;
    }
  }
}