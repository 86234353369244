// Carrera
// Carrera
.carrera {
  .columns .column .columns {
    margin-bottom: 0;

    .column {
      padding-bottom: 0;
    }
  }
  .extra-info {
    font-size: 1.4rem;
    margin-bottom: 0.7rem;

    span {
      color: var(--key-color);
      font-weight: bolder;
    }
  }
  .modal {
    flex-direction: inherit;
    z-index: 100;

    &.is-active {
      .modal-card {
        animation: modalIn 0.2s ease-in-out 0s 1 normal;

        @keyframes modalIn {
          0% {
            top: -$gap * 2;
            opacity: 0;
          }

          100% {
            top: 0;
            opacity: 1;
          }
        }

        .ribbon {
          top: 8px;
        }

        div.subject {
          display: grid;

          .actions {
            margin-top: 0.75rem;
            display: flex;
            justify-content: right;
          }
        }

        a.load-page {
          margin: 1.5rem 0;
        }
      }
    }

    &.is-inactive {
      .modal-card {
        animation: modalOut 0.2s ease-in-out 0s 1 normal;

        @keyframes modalOut {
          100% {
            top: -$gap * 2;
            opacity: 0;
          }

          0% {
            top: 0;
            opacity: 1;
          }
        }
      }
    }

    .modal-background {
      background-color: rgba($grey, 0.5);
    }

    .modal-card {
      overflow: visible;
      order: 1;
      margin: unset;

      .modal-card-head {
        background-color: $blanco;
        border-radius: 0 !important;
        border: 0;
        display: flex;
        justify-content: flex-end;

        a {
          color: $grey;

          &:hover {
            color: var(--key-color);
          }
        }
      }

      .modal-card-body {
        padding: 0 32px 20px 32px;

        h2 {
          font-size: 18px;
        }

        .ribbon {
          background-color: var(--key-color);
          left: 0;
          position: absolute;
          width: auto;
        }

        .load-page {
          color: var(--key-color);
          font-weight: bold;
          font-size: 2rem;
        }

        .actions {
          float: right;

          a {
            padding: 0 5px;
            color: var(--key-color);

            &:hover {
              color: $grey;
            }
          }
        }
      }
    }

    .chevron {
      color: $grey;
      font-size: $size-3;
      z-index: 99;
      padding: 10px;

      &:hover {
        color: var(--key-color);
      }

      &.nav-left {
        order: 0;
      }

      &.nav-right {
        order: 2;
      }
    }
  }

  .social-share {
    padding: 0 !important;
  }
}

.carrera .container > .columns {
  margin-bottom: 0 !important;
}