.block-banner {
    .overlay {
        &:after {
            background-color: rgba($blue, .3);
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    // margin: 64px 0 !important;
    position: relative;
    .container {
        align-self: center;
        .legend {
            position: relative;
            min-height: 252px;
            @include mobile {
                padding: $gap / 2;
                margin-right: 0;
                margin-left: 0;
            }
            h3 {
                color: $white;
                font-weight: bolder;
                font-family: "BaiJamjuree",sans-serif;
                line-height: 3.5rem;        
                font-size: 2.5rem;
             }
            h4 {
                color: $white;
                font-family: "BaiJamjuree",sans-serif;
                line-height: 2.75rem;        
                font-size: 1.75rem;
             }
             p {
                 text-align: center;
             }
             a{
                font-size: $size-6;
                font-family: $family-primary;
                background-color: #fafafa;
                border-color: #e6e6e6;
                border-width: 1px;
                color: $blue !important;
                cursor: pointer;
                justify-content: center;
                padding-bottom: calc(0.5em - 1px);
                padding-top: calc(0.5em - 1px);
                text-align: center;
                white-space: nowrap;
                border-radius: 9999px;
                padding-left: calc(1em + 0.25em);
                padding-right: calc(1em + 0.25em);

            }
        }

        .image {
            height: 100%;
            width: 100%;
            position: absolute;
            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .box-banner1 {
        .container {
            .legend {
                position: relative;
                min-height: 252px;
                @include mobile {
                    padding: $gap / 2;
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }
    }

    .box-banner2 {
        .container {
            min-width: 100%;
            min-width: -moz-available;
            min-width: -webkit-fill-available;
            min-width: fill-available;
            min-height: 252px;
        }

        .is-not-clickable {
            cursor: default;
        }
    }
    .box-banner3 {
        .container {
            min-width: 100%;
            min-width: -moz-available;
            min-width: -webkit-fill-available;
            min-width: fill-available;
            min-height: 26rem;
            .legend{
                min-height: 26rem;
            }
        }

        .is-not-clickable {
            cursor: default;
        }
    }
}