// Footer
footer {
  position: relative;
  margin-top: $gap;
  
  &:after {
    background: url(../img/bgFooterUNC.svg) no-repeat right center;
    content: " ";
    position: absolute;
    width: 15%;
    height: 100%;
    top: 0; 
    right: 0;
    display: none;

    @include tablet {
      display: block;
    }
  }

  .hero {

    &.is-dark {
      padding: 0;

      > div {
        justify-content: center;
        flex-wrap: wrap;
        padding: $gap / 4;

        @include desktop {
          padding: $gap / 2;
        }

        h5 {
          width: 50%;
          margin-bottom: 20px;
          padding-top: 20px;

          @include tablet {
            width: 15%;
            margin-bottom: 30px;
            padding-top: 0;
          }

          img {
            max-width: 110px;
          }
        }

        ul {
          padding: $column-gap;

          li {

            a {
              color: $white;
              font-size: 90%;
              display: block;
              padding: 8px 0;
            }

            &:last-child {

              a {
                border-bottom: none;
              }
            }
          }
          
          @include tablet {
            
          }
        }
      }
    }
    
    &.is-black {
      
      .hero-body {
        padding: $gap / 4;

        @include desktop {
          padding: $gap / 2;
        }
      }

      >div {
        flex-wrap: wrap;
      }

      img {
        margin-right: 20px;
      }

      p {
        font-size: $size-7;
      }
    }
  }
}

footer {

  .flat-menu.footer {
    background-color: transparent;
    padding: 0;
    width: 100%;
  
    ul {
      display: flex;
      flex-wrap: wrap;

      @include tablet {
        flex-direction: column;
        height: 186px;
      }

      li {
        width: 100%;

        @include tablet {
          width: auto;
        }
      }
    }
  }
} 

.home {

  footer {
    margin-top: 0;
  }
}