.news {

  .columns {

    &.listado {
      
      .column {
        width: 100%;
        flex-basis: auto;
        flex-grow: 0;
  
        @include tablet {
          width: 25%;
        }
  
        .box {
          border-radius: 0;
          box-shadow: none;
          display: flex;
          padding: 0;
          height: 100%;
          width: 100%;
  
          .level.is-mobile {
            font-size: $size-7;
            display: inline-block;
            word-wrap: break-word;
          }
  
          .card {
            background: $pastelClaro;
            box-shadow: none;
            width: 100%;
  
            .title {
              margin: ($gap / 6) 0;
            }
  
            figure {
              margin-bottom: 10px;
            }
  
            h2 {
              font-size: $size-4;
            }
  
            h3 {
              font-size: $size-5;
            }
          }
  
          &.career {
  
            .card {
  
              .title {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.searches {

  .event .card-image {
    padding: 1.25em;
  }

  .columns {

    .column {
      width: 100%;
      flex-basis: auto;
      flex-grow: 0;

      @include tablet {
        width: 25%;
      }

      .box {
        border-radius: 0;
        box-shadow: none;
        display: flex;
        padding: 0;
        height: 100%;
        width: 100%;

        .level.is-mobile {
          font-size: $size-7;
          display: inline-block;
          word-wrap: break-word;
        }

        .carrera {

          .title {

            a.image {
              display: inline-block;
              vertical-align: text-bottom;
              margin-left:-5px;
            }
          }
        }

        .card {
          background: $pastelClaro;
          box-shadow: none;
          width: 100%;

          .title {
            margin: ($gap / 6) 0 ;
          }

          figure {
            margin-bottom: 10px;
          }

          h2 {
            font-size: $size-4;
          }

          h3 {
            font-size: $size-5;
          }
        }

        &.career {

          .card {

            .title {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.generic {
  .column {
    height: 100%;
  }
}