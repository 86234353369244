//carreras_list
.careers{
  .header-inner{
    .image{
      padding-right: 8px;

      path{
        fill: $verdeAgua;
      }
    }
    .title{
      align-self: center;
    }
  }
  .columns{
    margin-left: -1.75rem;
    margin-right: -1.75rem;

    .column{
      padding: 1.75rem;

      .career {
        padding: unset;
      }
    }
  }
}