.home-careers {
    height: 100%;
    display: none;
    margin-bottom: 0 !important;

    @include tablet {
        display: flex;
    }

    .level-item {

        .tooltip {
            z-index: 25;
        }

        .tooltip::before {
            bottom: 115%;
        }

        .tooltip:hover::before, 
        .tooltip.is-tooltip-active::before {
            background: var(--career-color);
            font-family: Dosis;
            font-size: $size-7;
            font-weight: 400;
            padding: .3rem .8rem;
        }

        .tooltip:focus:not(.is-loading)::after, 
        .tooltip:hover:not(.is-loading)::after, 
        .tooltip.is-tooltip-active:not(.is-loading)::after {
            border-color: var(--career-color) transparent transparent transparent;
            top: -15%;
        }

        a {
            text-transform: uppercase;
            display:block;
            height: $icon-dimensions-large;
            width: $icon-dimensions-large;
            filter: grayscale(100%);
            outline: none;

            &:hover {
                filter: grayscale(0%);
            }

            &.tooltip:before {
                bottom: 114%;
            }

            .cls-1 {
                fill: $grey !important;
            }

            &:hover .cls-1 {
                fill: var(--career-color) !important;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }   
    }
}