@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

div[class^="block-"] {

    .rich-text {
        display: inline;

        h2 {
            margin-bottom: $gap / 3;
        }

        hr {
            background-color: $home-events-2;
        }
    }

    h2 {
        color: $grey-dark;
        font-size: $size-4;
        line-height: 28px;
        letter-spacing: 0.05rem;
    }

    h3,
    h4 {
        color: $grey-dark;
        font-size: $size-5;
        line-height: 24px;
        letter-spacing: 0.05rem;
    }

    h2,
    h3,
    img,
    div,
    a {
        clear: both;
    }

    p {
        font-weight: 500;
        line-height: 28px;
        font-size: 14px;
        text-align: justify;
        letter-spacing: 0.05em;
    }

    ul,
    ol {
        font-size: 14px;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 1em;
        line-height: 28px;
        letter-spacing: 0.8px;
    }

    a {
        color: var(--key-color);
        font-weight: bold;
        word-wrap: anywhere;

        &:hover {
            text-decoration: underline;
        }
    }

    .richtext-image {
        img {
            max-width: 100%;
        }

        &.right {
            float: right;
            margin: 10px 0 25px 50px;
            max-width: 360px;
        }

        &.left {
            float: left;
            margin: 10px 50px 25px 0;
            max-width: 360px;
        }

        &.full-width {
            width: 100%;
        }

        .collapsible {
            padding: $gap / 6 0;

            a {
                text-decoration: none;
            }

            h2 {
                font-size: 1.25rem;
                display: inline;
            }
        }
    }
}
.home{
    div[class^="block-"] {
        margin: 0 auto;
    }
}
.content{
    .block-news,
    .block-events,
    .block-banner,
    .block-related_content,
    .block-suggested_link {
    text-align: initial;
    margin: $gap*2 0;
    }

    .block-video div {
    display: flex;
    justify-content: center;
    padding: $section-padding;
    }
    .block-gallery {
    padding: $gap 0;

        img {
            display: block;
        }

        .glide__bullets {
            bottom: 10%;
        }
    }  
    .glide__slides {
        margin-left: 0;
    }
}


.block-related_content {
    .box {
        padding: 0;
    }
    .card{
        .card-content{
            .content{
                display:none;
            }
        }
    }
}

// Scroll index
.scroll-index {
    margin-bottom: $text-margin;
    margin-top: $title-margin;
    scroll-margin-top: $title-margin;
}

.block-title {
    font-size: 1.25rem;
    display: inline;
    vertical-align: baseline;
    font-weight: 600;
    color: $black;
}

.block-gallery {
    .glide {
        margin: 0 auto;
        max-width: calc(100vw - 24px);
        width: 100%;

        @include desktop {
            max-width: 68vw;
        }
    }

    .glide__track {
        padding: 0;
        height: 100%;

        .glide__slides {
            display: flex;
            z-index: 0;
            padding: 0;
            margin: 0;
            height: 100%;

            .glide__slide {
                position: relative;
                overflow: hidden;
                padding: 0;
                margin: 0;
                height: 100%;

                figure iframe {
                    bottom: 0;
                    position: absolute;
                    top: 46%;
                    transform: translate(-50%, -50%);
                }
                figcaption {
                    color: $blue;
                }
            }
        }
    }

    .glide__arrows {
        .glide__arrow {
            background: $white;
            color: $grey;
            border: none;
            font-size: 2rem;
        }

        .glide__arrow--left {
            left: 1rem;
        }

        .glide__arrow--right {
            right: 1rem;
        }
    }
}

// Embed
.responsive-object {
    padding: 0 !important;
    margin: 20px 0;

    iframe {
        height: 360px;
        width: 100%;

        @include tablet {
            height: 460px;
        }
    }
}

.block-table {
    margin: $gap / 2 0;
}

.block-embed {
    iframe {
        width: 100%;
        .ui-rtsr div,
        .mv-daynames-table,
        .view-cap,
        .view-container-border {
            background-color: #ccc;
        }
    }
}

.block-document {
    margin: 8px 0;
    padding: 4px 20px;
    position: relative;

    a {
        display: block;
        text-decoration: none;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            height: 22px;
            width: 13px;
        }
    }
}
