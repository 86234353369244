// Breadcrumb
.breadcrumb {
  background: none;
  color: $grey;
  padding-bottom: 10px;
  margin-bottom: 0 !important;
  font-size: 0.75rem;

  ul {

    li {

      &:before {
        color: $grey;
      }
      
      a {
        color: $grey;
      }
      
      &.is-active {
        font-weight: bold;

        a {
          color: $grey;
        }
      }    
    } 
  }
}