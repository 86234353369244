// Header
.header {
  line-height: 0;
  position: relative;

  img {
    width: 100%;
    max-height: 60vh;
    object-fit: cover;
  }

  .header-inner {
    order: 1;

    @include desktop {
      flex-flow: inherit;
    }

    figure {
      display: inline-block;
      margin-right: $gap / 6;
      vertical-align: middle;
      align-self: left;
      animation-name: icon-pulse;
      animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
      animation-iteration-count: 2;
      animation-fill-mode: none;
      animation-play-state: running;
      animation-delay: 600ms;
      animation-duration: 800ms;

      img {
        height: auto;
        width: 100%;
      }
    }

    .title {
      width: 100%;
      color: var(--key-color);
      font-weight: bold;
      flex-grow: 1;
    }
  }

  .social-share {
    order: 2;
    margin: 0;
    float: none;
    .buttons {
      justify-content: flex-start;

      @include desktop {
        justify-content: flex-end;
      }

      > span {
        display: none;

        @include desktop {
          display: inline-block;
        }
      }

      .button {
        padding: 0 0.5em;
        height: 100%;

        &.social-share-link {
          padding-left: .2em;

          @include desktop {
            padding-left: 0.75em;
          }

        }
      }
    }
  }

  .news-date {
      order: 3;
  }

  &.image {

    .header-inner {
      position: relative;
      left: 0;
      bottom: 0;
      align-content: center;

      h1 {
        display: inline-block;
        margin: .3em 0;
        align-self: center;
        font-size: $size-4;

        @include desktop {
          font-size: $size-3;
        }
      }
    }
  }
}

.event {
  .header
    {
      .header-inner {
        .title{

          display: block;
        }

      .subtitle{
        margin-top:0;
      }
    }
  }
}

@keyframes icon-pulse {
  0% {
    transform: rotate(180deg) scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5) rotate(360deg);
    opacity: 0.3;
  }
  100% {
        transform: rotate(0deg);
    transform: scale(1);
    opacity: 1;
  }
}