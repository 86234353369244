.home-events-list {
  .card {
    overflow: visible;
    $colors: $home-events-1, $home-events-2, $home-events-3, $home-events-4,
      $home-events-5, $home-events-1, $home-events-2, $home-events-3,
      $home-events-4, $home-events-5, $home-events-1, $home-events-2,
      $home-events-3, $home-events-4, $home-events-5;

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        .timer {
          background-color: nth($colors, $i);
        }
      }
    }

    border: none;
    box-shadow: none;
    padding-top: $gap / 2;
    padding-bottom: $gap / 2;
    .card-content {
      margin: 0;

      > div {
        display: flex;
        flex-flow: row wrap;

        .svg-inline--fa {
          float: left;
          margin: 5px 5px 5px 0;
        }
      }
    }

    a {
      &:hover {
        color: var(--key-color);
      }
    }

    .title {
      margin: 0 0 $gap / 4;

      a {
        color: $grey;
      }
    }

    &:hover {
      cursor: hand;

      .timer {
        background-color: $verdeAgua !important;
      }

      .title {
        color: $verdeAgua;
      }
    }

    .timer {
      color: $white;
      text-align: center;
      width: 100%;
      position: relative;
      margin-bottom: $gap / 2;
      transition: 0.5s;

      @include tablet {
        border-radius: 50%;
      }

      .radius {
        display: none;
        position: absolute;
        transition: all 0.7s ease;
        width: 16px;
        height: 108%;
        top: -4%;
        left: 46%;

        @include tablet {
          display: initial;
        }

        .point {
          height: 16px;
          width: 16px;
          background-color: $home-news-2;
          border-radius: 50%;
        }
      }

      &:before {
        content: "";
        display: block;
        padding-top: 0; /* initial ratio of 1:1 */

        @include tablet {
          padding-top: 100%; /* initial ratio of 1:1 */
        }
      }

      .date {
        position: relative;
        top: 0;
        left: 0;
        font-size: 600%;
        font-family: "Raleway Extralight";
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        @include tablet {
          position: absolute;
        }

        span {
          display: inline-flex;
          margin: -5% 0 0 0;

          &:last-child {
            font-family: "Raleway";
            font-size: 16px;
            text-transform: uppercase;
            margin: 10% 0 0 2%;
          }
        }
      }
    }
  }
}

.search {
  .card {
    $colors: $home-events-1, $home-events-2, $home-events-3, $home-events-4,
      $home-events-5, $home-events-1, $home-events-2, $home-events-3,
      $home-events-4, $home-events-5, $home-events-1, $home-events-2,
      $home-events-3, $home-events-4, $home-events-5;

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        .timer {
          background-color: nth($colors, $i);
        }
      }
    }

    border: none;
    box-shadow: none;

    .card-content {
      margin: 0;

      > div {
        display: flex;
        flex-flow: row wrap;

        .svg-inline--fa {
          float: left;
          margin: 5px 5px 5px 0;
        }
      }
    }

    a {
      &:hover {
        color: var(--key-color);
      }
    }

    .title {
      margin: 0 0 $gap / 4;

      a {
        color: $grey;
      }
    }

    &:hover {
      cursor: hand;

      .timer {
        background-color: $verdeAgua !important;
      }

      .title {
        color: $verdeAgua;
      }
    }

    .timer {
      color: $white;
      text-align: center;
      width: 100%;
      position: relative;
      margin-bottom: $gap / 2;
      transition: 0.5s;

      @include tablet {
        border-radius: 50%;
      }

      .radius {
        display: none;
        position: absolute;
        transition: all 0.7s ease;
        width: 16px;
        height: 108%;
        top: -4%;
        left: 46%;

        @include tablet {
          display: initial;
        }

        .point {
          height: 16px;
          width: 16px;
          background-color: $home-news-2;
          border-radius: 50%;
        }
      }

      &:before {
        content: "";
        display: block;
        padding-top: 0; /* initial ratio of 1:1 */

        @include tablet {
          padding-top: 100%; /* initial ratio of 1:1 */
        }
      }

      .date {
        position: relative;
        top: 0;
        left: 0;
        font-size: 600%;
        font-family: "Raleway Extralight";
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        @include tablet {
          position: absolute;
        }

        span {
          display: inline-flex;
          margin: -5% 0 0 0;

          &:last-child {
            font-family: "Raleway";
            font-size: 16px;
            text-transform: uppercase;
            margin: 10% 0 0 2%;
          }
        }
      }
    }
  }
}
