.home {

    #map {
        @include radial-gradient(#555a5f, #333);
        display: none;
        justify-content: center;
        flex-flow: row wrap;

        @include desktop {
            display: flex;
        }

        .map-description {
            text-align: center;

            h3 {
                font-family: "Dosis";
                color: $blanco;

                span {
                    color: $naranja;
                }
            }

            p {
                font-family: "Dosis";
                font-size: 14px;
                color: $blanco;
                justify: center;
            }
        }

        .map-viewer {
            position: relative;
            width: 100%;
            min-height: 600px;
            display: flex;
            justify-content: center;
            align-items: center;

            .markers {
                position: absolute;
                top: 10%;
                left: 10%;
                width: 80%;
                height: 80%;

                .map-marker {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-top: -2.5px;
                    margin-left: -2.5px;


                    .map-marker-area,
                    .map-marker-center {
                        display: block;
                        position: absolute;
                        background-color: #fff;
                        border-radius: 50%;
                    }

                    .map-marker-center {
                        top: 0;
                        left: 0;
                        z-index: 1;
                        width: 15px;
                        height: 15px;
                        transform: scale(0.6);
                        transition: transform 0.3s ease;
                        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
                        -webkit-backface-visibility: hidden;

                        &:hover {
                            transform: none;
                        }
                    }

                    .map-marker-area {
                        top: 7px;
                        left: 7px;
                        margin-top: -10.5px;
                        margin-left: -10.5px;
                        width: 21px;
                        height: 21px;
                        -webkit-transform: scale(0);
                        -ms-transform: scale(0);

                    }

                    .map-marker-info {
                        display: none;
                        color: white;
                        background-color: rgba(0, 0, 0, 0.4);
                        margin-left: 10.5px;
                        padding: 0 1rem;
                        width: 25rem;
                        position: absolute;
                        z-index: 10;
                        left: -50%;
                        position: relative;
                        top: 15px;

                        .map-marker-info-content,
                        .map-marker-info-title {
                            margin: 0;
                            padding: 0.5em 0;
                        }

                        .map-marker-info-title {
                            border-bottom: 1px solid;
                            white-space: nowrap;
                            font-weight: bold;
                            font-size: 0.9rem;
                        }

                        .map-marker-info-content {
                            font-size: 0.8rem;
                        }

                    }

                    &.hover,
                    &.active{
                      .map-marker-area {
                        animation-name: pulse;
                        animation-timing-function: ease;
                        animation-iteration-count: 3;
                        animation-fill-mode: none;
                        animation-play-state: running;
                        animation-duration: 1333ms;
                      }
                      .map-marker-center {
                        background-color: $naranja;
                        z-index: 1500;
                      }

                      .map-marker-info {
                        opacity: 0;
                        display: block;
                        animation-name: showText;
                        animation-timing-function: ease;
                        animation-iteration-count: 1;
                        animation-fill-mode: none;
                        animation-play-state: running;
                        animation-duration: 5200ms;
                      }
                    }

                    &.hover{
                      .map-marker-info{
                        animation-duration: 8s;
                      }
                    }
                }

            }

            svg {
                width: 80%;
                //height: 80%;
                .pt {
                    fill: #838383;
                }
            }
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 0.8;
    }

    100% {
        transform: scale(4);
        opacity: 0;
    }
}
@keyframes showText {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  20%{
    opacity: 1;
    transform: scale(1);
  }
  70%{
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}