.socialbar {
  display: flex;
  align-items: center;

  li {
    display: flex;
    margin-left: 10px;

    a {
      background: $blue;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: 28px;

      svg {
        color: $white;
        height: 16px !important;
        width: auto !important;
      }
    }
  }
}
