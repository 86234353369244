.block-slider {
    @extend .container;
    
    .slider-block {
        margin: $gap auto;
        width: 100%;
        position: relative;

        .glide__slides {

            .glide__slide {
                position: relative;

                .bg-image {
                    height: 100%;

                    .image {
                        height: 100%;
                        
                        img {
                            min-height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .content-image {
                    box-sizing: border-box;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;

                    .rich-text {
                        color: $blanco;
                        padding: $gap * 2;
                        
                        h3 {
                            color: $blanco;
                            font-weight: bolder;
                            padding-bottom: 0;
                            
                            a {
                                color: $blanco;
                            }
                        }
                        h4 {
                            color: $blanco;
                            font-weight: 300;
                            word-break: break-word;

                            a {
                                color: $blanco;
                                font-size: inherit;
                            }
                        }
                        p {
                            text-align: inherit;
                            a {
                                @extend .button;
                                font-size: inherit;
                            }
                        }
                    }
                }
            }
        }
    }

    .btn {
        $icon-size: 16px;
        $opacity-100: 1;
        $opacity-80: 0.7;
        $opacity-50: 0.5;
        cursor: pointer;

        &.btn--rounded {
            border-radius: 50%;
        }

        &.btn--size {
            padding: ($icon-size - 8);
        }

        &.btn--grey-light {
            border: 1px solid rgba($grey-light, $opacity-80);
            .icon {
                opacity: $opacity-80;
                color: $grey-light;
            }
        }

        .icon {
            font-size: $icon-size;
        }

        &:hover {
            border: 1px solid $blanco;
            opacity: $opacity-100;
            .icon {
                opacity: $opacity-100;
            }
        }

    }

    .icon {
        $icon-padding-x: 28px;
        $icon-padding-y: 28px;

        &.is-rounded {
            border: 2px solid $white;
            border-radius: 50%;
            padding: $icon-padding-y $icon-padding-x;
        }
    }


    .glide__bullets {
        max-width: 50%;
        bottom: $gap;
        z-index: 20;

        .glide__bullet {
            margin: 0 $gap / 4;
            background-color: $blanco;
            box-shadow: none;
            &.glide__bullet--active {
                background-color: $primary;
            }
            &:hover,
            &:focus {
                border: transparent;
            }
        }
        &.bullets-outside {
            bottom: -$gap;
        }
    }


}
