// Colores pastel
$pastelClaro: #f8f8f8;
$pastelMedio: #f2f2f2;
$pastelOscuro: #eaeaea;

// Colores grises
$verdeAgua: #0b8bab;  		// verde agua
$green: #0b8bab;
$blue: #288bab;
$naranja: #f08f00;	  		// naranja
$violeta: #915fa0;
$red: #E85844;
$blanco: #ffffff;     		// blanco
$black-ter: hsl(0, 0%, 14%);
$grey-lighterHover: #666666;    // gris menu hover
$grey-dark: hsl(0, 0%, 29%);
$grey-darker:  #3e3e3d;
$grey-light: #c4c4c4;
$grey-lighter:#e0e0e0;
$grey: #555554;
$black: #333333;
$black-bis: #333333;
$primary: $verdeAgua;
$home-news-1: #0A89A9;
$home-news-2: #1EADBC;
$home-news-3: #3CB8C1;
$home-news-4: #58C8CE;

$menu-0: #1896B5;
$menu-1: #15A1B7;
$menu-2: #1EADBC;
$menu-3: #2FB4BD;
$menu-4: #50BAC0;


$home-events-1: #C3C3C3;
$home-events-2: #9C9C9C;
$home-events-3: #848181;
$home-events-4: #4F4F4F;
$home-events-5: #232322;

$box-background-color: transparent;

// Menu
$logoSmall: 56px;
$logoMedium: 74px;
$background: #FFF;
$accordion-background-color: $verdeAgua;
// Fuentes
$family-sans-serif: 'Raleway', Verdana, Helvetica, sans-serif;
$family-primary: $family-sans-serif;
$family-tertiary: 'Droid Serif';
$family-secondary: $family-sans-serif;
$title-font: $family-primary;
// $family-secondary: 'Dosis', Tahoma, Helvetica, sans-serif;

// Texto
$title-margin: 5rem;
$text-margin: 1.5rem;
$list-margin: 2rem;


// bulma variables
$primary: $verdeAgua;
$link: $blue;
$text: $black;
$dark: $grey;
$black: $grey-dark;
$tag-background-color: var(--key-color);

// Context variable
$key-color: $blue;
:scope {
    --key-color: #{$key-color};
}
