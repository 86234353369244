// scrollSpy

.scrollspy{
  font-size: 1rem;
  display: none;

  @include desktop {
      display: block;
  }

  &.is-fixed-top{
    position: sticky;
    top: $logoMedium + $gap * 2;
  }

  .steps-segment{
    position: relative;
    &:not(:last-child)::after {
      bottom: -1rem;
      left: calc(1rem - (0.2em));
      top: 1.76rem;
      width: 0.4em;
      content: " ";
      display: block;
      position: absolute;
    }
    &::after{
      border-width: 0;
      background-color: $grey-light !important;
      left: unset !important;
      width: 0.2em !important;
      right: calc(0.6rem - (0.2em));
      top: 1rem !important;
    }
    &:not(:last-child) {
      min-height: 3em;
    }
    &.is-visited .steps-marker{
      background-color: $grey-light !important;
      border-color: $grey-light;
    }
    &.is-active .steps-marker{
      background-color: $blue !important;
      border-color: $blue;
      transition: .5s;

      &:before {
        background-color:$blue;
        border-radius: 50%;
        z-index: 1;
        content: "";
        position: absolute;
        height: 1rem;
        width: 1rem;
        // -webkit-animation: scale-scroll-spy 1s ease-in;
      }
    }
  }

  .steps-marker{
    align-items: center;
    display: flex;
    border-radius: 50%;
    font-weight: 700;
    justify-content: center;
    z-index: 10;
    position: absolute;
    border: 0.2em solid $grey-light;
    background-color:$blanco !important;
    right: 0px;
    height: 1rem;
    width: 1rem;
    top: 0.28em;
    transition: .5s;
  }
  .steps-content {
    width: 90%;
    font-family: $family-secondary;
    margin-top: 0;
    float: right;
    text-align: right;
    margin-right: 10%;
    color: $grey;
    margin-top: -1px;

    &:hover{
      color: $blue;
    }
  }
  .is-active .steps-content{
    color: $blue;
  }
}

/* Scroll Spy Animation */
@keyframes scale-scroll-spy {
  0% {
    -webkit-transform: scale(1,1);
    opacity:0.6
  }
  100% {
    -webkit-transform: scale(1.5,1.5);
    opacity:0;
  }
}
