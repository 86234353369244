.social-share {
  margin: 0;
  
  .buttons {
    background: transparent;
    .button {
      background: transparent;
      font-size: 1.4rem;
      margin-bottom: 0;
      padding-right: 0;
      .icon {
        color: rgba(50, 50, 50, 0.3);
      }
      &.is-tooltip-success {
        &:before {
          background-color: var(--key-color);
        }
        &:after {
          border-color: var(--key-color) transparent transparent transparent;
        }
      }

      &:hover .icon,
      &.is-hovered .icon {
        color: var(--key-color);
      }
    }

    & > span {
      font-size: 0.7rem;
    }
  }
}
