// rem fallback - credits: http://zerosixthree.se/
@function calculateRem($size) {
  $remSize: $size / 14px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin radial-gradient($from, $to) {
	background: -moz-radial-gradient(center, circle farthest-corner, $from 0%, $to 100%);
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $from), color-stop(100%, $to));
	background: -webkit-radial-gradient(center, circle farthest-corner, $from 0%, $to 100%);
	background: -o-radial-gradient(center, circle farthest-corner, $from 0%, $to 100%);
	background: -ms-radial-gradient(center, circle farthest-corner, $from 0%, $to 100%);
	background: radial-gradient(center, circle farthest-corner, $from 0%, $to 100%);
	background-color: $from;
}
