.block-suggested_link {
  figure {
    background: #f8f8f8;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    align-items: stretch;

    figcaption {
      font-style: normal;
      padding: $gap;
      @include tablet {
        text-align: left;
      }

      .reference {
        background-color: $green;
        box-sizing: border-box;
        color: $white;
        display: inline-block;
        font-size: 12px;
        padding: 3px 10px;
        position: absolute;
        top: 0;
        left: 0;

        @include tablet {
          position: relative;
          top: -10px;
          left: -10px;
        }
      }

      h3 {
        font-weight: bold;
        margin: 0;

        a {
          color: $grey-darker;
        }
      }
    }
    @include tablet {
      display: flex;
      align-items: flex-start;
    }

    &.no-image {
      justify-content: center;
      align-items: center;

      figcaption {
        text-align: center;

        .reference {
          left: 0;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 0;
    }

    > * {
      @include tablet {
        width: 50%;
      }
    }

    img {
      display: block;
    }
  }
}
