// Listado
.listado {

  .subtitle {
    font-weight: bold;
  }

  .card-content {
    font-size: 80%;
    margin: 16px 18px;
    padding: 0;
    padding-bottom:16px;
    border: none;
  }
  .carrera {
    .title {
      a.image {
        display: inline-block;
        vertical-align: text-bottom;
        margin-left:-5px;
      }
      a {
        font-size:90%;
      }
    }
  }

  .card-footer {
    font-size: $size-6;
    margin: 16px 18px;
    padding: 0;
    border: none;
  }

  h4 {
    color: $verdeAgua;
    font-family: $family-secondary;
    font-weight: 300;
    margin: 20px 0;
    text-transform: uppercase;
  }
}

.listings {

  .card {
    color: $blanco;
    border: none;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    height: 100%;

    .title {
      margin: 0;

      a {
        color: $home-events-5;
      }
    }

    .subtitle {
      margin-bottom: $gap / 5;

      a {
        color: $home-events-5;
        font-size: $size-7 * 0.9;
        text-transform: uppercase;
      }
    }

    .content {
      color: $home-events-4;
      margin-top: $gap / 5;
    }

    time {
      color: $home-events-5;
    }

    img {
      transition: filter 1s ease;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 1;
    }

    &:hover,
    &:focus {
      cursor: pointer;

      img,
      img:hover {
        mix-blend-mode: unset;
        transition: all 1s ease;
        -webkit-filter: none;
        filter: none;
      }

      .title,
      .title a,
      .subtitle,
      time {
        color: $black;
      }
    }
  }

  .card-footer {
    margin-top: auto;
  }
}

.Resumen,
.home {

  .listings {
    .button a {
      z-index: 20;
    }
    .columns {
      justify-content: center;

      .column {
        $colors: $home-news-1, $home-news-2, $home-news-3, $home-news-4;
        @for $i from 1 through 4 {
          &:nth-child(#{$i}), &:nth-child(#{$i + 4}), &:nth-child(#{$i + 8}) {
            .card {
              background-color: nth($colors, $i);
            }
          }
        }
      }
    }
  }
}

.home-events-list {

  .columns {
    justify-content: center;

    .column {
      $colors: $home-events-1, $home-events-2, $home-events-3, $home-events-4,
        $home-events-5, $home-events-1, $home-events-2, $home-events-3,
        $home-events-4, $home-events-5, $home-events-1, $home-events-2,
        $home-events-3, $home-events-4, $home-events-5;

      @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          .timer {
            background-color: nth($colors, $i);
          }
        }
      }
    }
  }

  .card {
    border: none;
    box-shadow: none;

    .title {
      margin: 0 0 $gap / 4;

      a {
        color: $grey;
      }
    }

    &:hover {
      cursor: hand;

      .timer {
        background-color: $verdeAgua !important;
      }

      .title {
        color: $verdeAgua;
      }
    }

    .timer {
      color: $white;
      text-align: center;
      width: 100%;
      position: relative;
      margin-bottom: $gap / 2;
      transition: 0.5s;

      @include tablet {
        border-radius: 50%;
      }

      .radius {
        display: none;
        position: absolute;
        width: 16px;
        height: 108%;
        top: -4%;
        left: 46%;
        transition: all 0.7s ease;

        @include tablet {
          display: initial;
        }

        .point {
          height: 16px;
          width: 16px;
          background-color: $home-news-2;
          border-radius: 50%;
        }
      }

      &:before {
        content: "";
        display: block;
        padding-top: 0; /* initial ratio of 1:1 */

        @include tablet {
          padding-top: 100%; /* initial ratio of 1:1 */
        }
      }

      .date {
        position: relative;
        top: 0;
        left: 0;
        font-size: 600%;
        font-family: "Raleway Extralight";
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        @include tablet {
          position: absolute;
        }

        span {
          display: inline-flex;
          margin: -5% 0 0 0;

          &:last-child {
            font-family: "Raleway";
            font-size: 16px;
            text-transform: uppercase;
            margin: 10% 0 0 2%;
          }
        }
      }
    }
  }
  // Teaser agenda
}

.content {
  #home-events-section, 
  #home-news-section {
    padding: 0;

    figure {
      margin: 0;
    }
  }
}

#container {

  &.object_list {
    padding-top: 76px;
  
    @include desktop {
      padding-top: 0;
    }
  
    .header {
      margin-top:0;
    }
  }
}

