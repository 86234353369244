.box {

    a {

        &:hover {
            color: var(--key-color);
        }

        h4 {
            font-size: $size-6;
        }

        p {
            font-size: 14px;
            margin-top: 8px;
        }
    } 

    &.events {
        padding: 0;

        li {
            box-shadow: none;
            display: flex;
            flex-flow: row wrap;

            .card-image {
                width: 25%;

                .timer {
                    color: $white;
                    text-align: center;
                    width: 100%;
                    position: relative;
                    margin-bottom: $gap / 2;
                    transition: 0.5s;
                    background: $home-events-1;
                
                    @include tablet {
                        border-radius: 50%;
                    }
                
                    .radius {
                        display: none;
                        position: absolute;
                        width: 8%;
                        height: 108%;
                        top: -4%;
                        left: 46%;
                        transition: all 0.7s ease;
                
                        @include tablet {
                        display: initial;
                        }
                
                        .point {
                        height: 16px;
                        width: 16px;
                        background-color: $home-news-2;
                        border-radius: 50%;
                        }
                    }
                
                    &:before {
                        content: "";
                        display: block;
                        padding-top: 0; /* initial ratio of 1:1 */
                
                        @include tablet {
                        padding-top: 100%; /* initial ratio of 1:1 */
                        }
                    }
                
                    .date {
                        position: relative;
                        top: 0;
                        left: 0;
                        font-size: 150%;
                        font-family: "Raleway Extralight";
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                
                        @include tablet {
                            position: absolute;
                        }
                
                        span {
                            display: inline-flex;
                            margin: -5% 0 0 0;
                    
                            &:last-child {
                                font-family: "Raleway";
                                font-size: 14px;
                                text-transform: uppercase;
                                margin: 8% 0 0 4%;
                            }
                        }
                    }
                }
            }

            .card-content {
                padding: 0 0 0 10px;
                width: 75%;

                h3 {
                    margin-bottom: 0;
                }

                div {
                    font-size: .85rem;
                    display: flex;
                    align-items: center;
    
                    .svg-inline--fa {
                        margin-right: 8px;
    
                        &.fa-w-16 {
                            width: .75em;
                        } 
    
                        &.fa-w-12 {
                            width: .65em;
                        }
                    }
                }
            }
        }
    }

    &.news {
        padding: 0;

        li {
            background: whitesmoke;
            border-radius: 5px;
            overflow: hidden;
        }
    } 
}

.selected_news .card {
    .card-footer {
        &:hover {
            color: var(--key-color);
        }
    }
}