.is-one-quarter {

    h2 {

        &.title {
            margin-bottom: .5rem;
        }
    }

    .box {
        margin-bottom: $gap / 2;

        &.events { 

            > li {
                margin-bottom: $gap / 3;

            }

        }
        
        &.news {
            
            > li {
                margin-bottom: $gap / 2;
            }
        }        
    }

    hr {
        background: $grey-light;  
        display: block;
        margin: 0 auto $gap / 2;
        height: 1px;
        width: 80%; 
    }
}